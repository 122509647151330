<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-6 mb-8 black--text">
        Czy na pewno chcesz cofnąć ten kurs?
      </div>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="cancelCourse(courseId)"
        @click="cancelCourse(courseId)"
      >
        Cofnij
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  computed: {
    ...mapState({
      item: state => state.layout.dialog.item,
      isProcessing: state => state.course.isProcessing
    }),
    courseId () {
      return this.item.course ? this.item.course.id : this.item.id
    }
  },
  methods: {
    ...mapActions({
      cancelCourse: 'course/cancelCourse'
    })
  }
}
</script>
